/* eslint-disable no-undef */
/* globals,__GB_LoginModalInfo__,gb_gdprRegister,_GB_LoginEventCenter_,POPUP_GB_ForceUpdatePwd */
// 这个文件会在服务端解析，请在使用客户端变量时加兼容
// 这个文件会在服务端解析，请在使用客户端变量时加兼容
// 这个文件会在服务端解析，请在使用客户端变量时加兼容
import {
  asyncLoadFile,
  patternEmail,
  template,
  getQueryString,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage
} from '@shein/common-function'
import { getSmsAbtResult, checkAttenBindInfo, showAttentiveA } from '../common/attentive/index'
import { reloadOneTrustEvents } from '../common/onetrust/index'
import schttp from 'public/src/services/schttp/index'
import { markPoint } from 'public/src/services/mark/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { registerPopInitData } from '@login/utils/common'

const { langPath, host, forceHttp, SiteUID, FB_APP_ID, FB_VERSION } =
  typeof gbCommonInfo === 'object' ? gbCommonInfo : {}

export const passwordPattren = /^(?=.*\d)(?=.*[a-zA-Z])[^\s]{8,}$/

// 检测邮箱合法性
export function checkEmail(email, tip) {
  const langText = window.loginLanguage || {}
  if (!email.length) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PWA_15589
    return false
  }
  if (!patternEmail({ email })) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PWA_15594
    return false
  }
  tip.show = false
  return true
}

// 检测注册邮箱合法性
export function checkSignUpEmail(email, tip) {
  const langText = window.loginLanguage || {}
  if (!email.length) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PWA_15589
    return false
  }
  if (!patternEmail({ email, signUp: true })) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PWA_15594
    return false
  }
  tip.show = false
  return true
}

export function formatErrorLoginInput(email, password, noPassword = false) {
  if (email === '') return 'empty_email'
  if (!patternEmail({ email })) return 'incorrect_email_format'
  if (noPassword) return
  if (password === '') return 'empty_password'
  if (password.length < 6) return 'password_less_than_6_characters'
  return 'other'
}

export function formatErrorLoginInputGa(email, password) {
  if (email === '') return 'Please fill in the mailbox'
  if (!patternEmail({ email })) return 'Mailbox validation failure'
  if (password === '') return 'Please enter the password'
  if (password.length < 6) return 'Password format is incorrect'
  return 'other'
}

export function formatErrorSignUpInput(email, password) {
  if (email === '') return 'empty_email'
  if (!patternEmail({ email, signUp: true })) return 'incorrect_email_format'
  if (password === '') return 'empty_password'
  if (!passwordPattren.test(password)) return 'invalid_password'
  return 'other'
}

export function formatErrorSignUpInputGa(email, password) {
  if (email === '') return 'Please fill in the mailbox'
  if (!patternEmail({ email, signUp: true })) return 'Mailbox validation failure'
  if (password === '') return 'Please enter the password'
  if (!passwordPattren.test(password)) return 'Password format is incorrect'
  return 'other'
}

// 格式化手机号码常规前端验证错误
export function formatErrorPhoneSa(code, password, mode) {
  if (mode == 'code') {
    if (code == '') return 'empty_verification_code'
  }
  if (mode == 'password') {
    if (password == '') return 'empty_password'
    if (password.length < 6) return 'invalid_password'
  }
  return 'other'
}

// 检测密码合法性
export function checkPw(pw, tip, type) {
  const langText = window.loginLanguage || {}
  if (!pw.length) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PWA_15593
    return false
  }
  if (pw.length < 6) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PWA_15591
    return false
  }
  if (type === 'sign-up' && !passwordPattren.test(pw)) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PWA_14898
    return false
  }
  tip.show = false
  return true
}

export const LoginPatterRegex =
  /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/

// 格式化url参数
export function querys() {
  const search = window.location.search.slice(1)
  const params = search.split('&')
  return params.reduce(function (obj, search) {
    const arr = search.split('=')
    obj[arr[0]] = decodeURIComponent(arr[1])
    return obj
  }, {})
}

function filtterSpecialUrl(url) {
  const { host, langPath } = gbCommonInfo
  let origin = `${host}${langPath}`
  if (url.indexOf(`${langPath}/`) >= 0) {
    origin = `${host}`
  }
  if (/^\//.test(url)) {
    return `${origin}${url}`
  }
  if (/^http(s)?:\/\//.test(url)) {
    var parser = document.createElement('a')
    parser.href = url
    if (parser.hostname === location.hostname) {
      return url
    }
    return origin
  }
  return `${origin}/${url}`
}

/**
 * 登录完成重定向
 * @param fromClose 是否来自页面关闭弹窗按钮
 * @param res 用户登录成功之后的数据
 * @returns {*}
 */
export const urlRedirection = async (fromClose, res, isAsComponent) => {
  // 设置全局强制改密弹窗缓存
  const { force_update_pwd = 0, member_id = '' } = res.info?.member || {}
  const cacheKey = `force_update_pwd_${member_id}`
  // 清除同个账号上次登录的缓存，避免改过密码还弹出
  if (getLocalStorage(cacheKey)) removeLocalStorage(cacheKey)
  if ([1, 2].includes(force_update_pwd)) {
    setLocalStorage({ key: cacheKey, value: force_update_pwd })
  }
  const query = querys()
  const _redirection = query.redirection || ''
  const _close_redirection = query.close_redirection
  const action = query.action

  if (fromClose) {
    markPoint('toNextPageClick', 'public')
    if (getQueryString({ key: 'from' }) === 'exchange' && (_close_redirection || _redirection)) {
      window.history.back()
      return
    }
    if (_close_redirection) {
      location.href = _close_redirection
      return
    }
    if (_redirection.indexOf('user') > -1) {
      location.href = `${langPath}/`
      return
    }
    if (_redirection.indexOf('checkout') > -1 || _redirection.indexOf('pay') > -1) {
      location.href = `${langPath}/cart`
      return
    }
  }

  // 多主体合规缓存清除
  window?.sessionStorage?.removeItem('mutiplePartPrivacy')
  window?.sessionStorage?.removeItem('isAgreenNewPrivacy')

  if (res?.info?.member && gbCommonInfo?.user) {
    try {
      gbCommonInfo.user = { ...res.info.member }
      delete gbCommonInfo.user.token
      delete res.info.member.token
    } catch (e) {
      /* empty */
    }
  }
  if (gbCommonInfo.user) {
    gbCommonInfo.user.email = gbCommonInfo?.user?.email ? '1' : ''
  }

  if (gbCommonInfo.user && gbCommonInfo.user.has_token_aes) {
    gbCommonInfo.HAS_TOKEN_AES = true
  }

  clearAbtInfo(res)

  showPopQueue(res)

  if (typeof exceptionReport != 'object') {
    const exceptionReport = (await import('public/src/pages/common/RiskCommon/libs/ExceptionReport/index')).default
    Object.assign(window.exceptionReport, exceptionReport)
    // window.exceptionReport = exceptionReport
  }
  window.exceptionReport.login()
  if (window.SHEIN_LOGIN?.instanceActions?.getOpts()?.actCb) {
    const actCb = window.SHEIN_LOGIN?.instanceActions?.getOpts()?.actCb
    if (typeof actCb === 'function') {
      actCb(res)
      return
    }
  }
  // 泄露名单处理
  if (res && res.info && res.info.member) {
    if (res.info.member.is_reveal == 1 && !res.cancelVerifyReveal) {
      _GB_LoginEventCenter_.$emit('event-loading', false)
      return
    } else if (res.info.member.isNewReveal == 1) {
      if (location.pathname.indexOf('/user/index') > -1) {
        location.reload()
      } else {
        location.href = langPath + '/user/blacklist_pwreset?scene=login_success'
      }
      return
    }
  }
  // 如果是来自全局弹窗登录页
  if (isAsComponent || (window.isLoginPageAsCompnent && window.SHEIN_LOGIN?.instanceActions)) {
    const { reload = true, to, cb, closeCb, bi, from } = window.SHEIN_LOGIN?.instanceActions?.getOpts()

    // 券包和checkout场景不需要全局强制改密弹窗
    if ((force_update_pwd == 2) && ['coupon', 'checkout', 'orderlist_related_account', 'findorder', 'paypal_checkout', 'paypal_checkout_normal'].includes(bi || from)) {
      removeLocalStorage(cacheKey)
    }
    if (fromClose) {
      // _GB_LoginEventCenter_.$emit('event-loading', false)
      // _GB_LoginEventCenter_.$emit('event-login-page-component', { show: false })
      // verification页面关闭登录弹窗
      closeCb && closeCb()
      return
    }

    if (to) {
      // 如果已经传进来登录成功跳转url
      location.href = to
      return
    }
    if (reload && cb === undefined) {
      // 重新加载
      location.reload()
      return
    }
    // 更新sa相关信息
    // 隐藏相关的loading
    _GB_LoginEventCenter_.$emit('event-loading', false)
    _GB_LoginEventCenter_.$emit('event-login-page-component', { show: false })

    // 用于首次登录成功跳转首页时刷新页面清缓存
    if (window._GB_LoginEventCenter_) {
      _GB_LoginEventCenter_._fristLoginSucc = true
    }

    if (typeof cb === 'function') {
      // 回到函数
      cb(res)
    }
    // 如果有展示快速注册弹窗，登录成功之后关闭注册弹窗，todo:以更优雅的方式关掉
    if (typeof window.gbRegisterModule !== 'undefined')
      window.gbRegisterModule.$children[0].rawHide()
    if (typeof window.welcomePrivacyVue !== 'undefined') window.welcomePrivacyVue.hideWelcome()
    if (typeof window.POPUP_GB_ForceUpdatePwd !== 'undefined') // 打开强制改密弹窗
      window.POPUP_GB_ForceUpdatePwd.$children[0].showHandle()
    return
  }

  // zendesk login
  if (action === 'JWTLogin') {
    return schttp({ url: `/api/auth/zendeskLogin/get${window.location.search}` }).then(
      data => {
        if (data.url) window.location = data.url
      }
    )
  }
  window._abt_server_provider &&
    window._abt_server_provider.clear &&
    window._abt_server_provider.clear()
  if (window.location.search.indexOf('direction=checkout') > -1) {
    window.location = `${host}${langPath}/checkout`
    return
  }

  if (_redirection != null) {
    // prot == 1 is from http
    if (getQueryString({ key: 'from' }) === 'exchange') {
      window.history.back()
      return
    }
    const redircPath = filtterSpecialUrl(_redirection)
    // window.location.href = redircPath
    window.location.replace(redircPath)
    return
  }
  window.location.href = `${forceHttp}${langPath}`
}

export function dataVisor() {
  return {
    register_country: getLocalStorage('country_tag_outdated') || '',
    resolution:
      window.screen.width * (window.devicePixelRatio || 1) +
      'X' +
      window.screen.height * (window.devicePixelRatio || 1),
    batterystate: '',
    batterylevel: '',
    register_TimeLong: window.signupTime || 0 // 注册时长
  }
}

// 获取浏览器数据
export function dataVisorCb(data, cb) {
  const biData = dataVisor()
  biData.register_type = data.register_type
  data.biData = biData
  cb(data)
}

// 展示隐私协议内容弹窗
export function privacyShow() {
  // welcomePrivacyVue.showContent = 'fromthirdLogin'
  window.SHEIN_GLOBAL_PRIVACY.show({ type: 'privacy' })
  if (typeof gb_gdprRegister !== 'undefined') {
    gb_gdprRegister('ClickPrivacyPolicy', '第三方登录')
  }
}

export async function startFacebookSdk() {
  window.fbAsyncInit = function () {
    FB.init({
      appId: FB_APP_ID, //正式
      status: true,
      cookie: false,
      xfbml: true,
      oauth: true,
      version: FB_VERSION
    })
  }
  const apiUrl = 'https://connect.facebook.net/en_US/sdk.js'

  await asyncLoadFile({
    label: 'script',
    attrs: { async: true, src: apiUrl, id: 'facebook-jssdk' }
  }).catch(() => {
    console.log('load facebook sdk failed...')
  })
}

// 获取Google验证码
export function fetchGoogleRecaptcha() {
  const recaptcha = 'https://www.google.com/recaptcha/api.js'
  const s = document.createElement('script')
  s.src = recaptcha
  s.async = true
  s.defer = true
  document.head.appendChild(s)
}
// 格式化三方统一接口登录的参数特异性
export function formatThirdLoginOptions(type, data) {
  const opts = { ...data }
  if (type === 11) {
    // fb
    opts.facebook_id = opts.socialId
    opts.fbtoken = opts.accessToken
    opts.register_type = 'facebook'
  } else if (type === 12) {
    // google
    opts.google_id = opts.socialId
    opts.id_token = opts.accessToken
    opts.register_type = 'google'
  } else if (type === 13) {
    // vk
    opts.user_id = opts.socialId
    opts.app_token = opts.accessToken
  } else if (type === 15) {
    // line
    opts.user_id = opts.socialId
    opts.id_token = opts.accessToken
  } else if (type === 16) {
    // line
    opts.kakao_id = opts.socialId
    opts.kakao_token = opts.accessToken
  } else if (type === 17) {
    // line
    opts.naver_id = opts.socialId
    opts.naver_token = opts.accessToken
  } 
  return opts
}

// 登陆注册来源是否是购买中
export function isFromShopping() {
  const { from, bi } = window.SHEIN_LOGIN?.instanceActions?.getOpts() || {}
  // 来自checkout页面的登录注册
  const buyScene = ['checkout', 'paypal_checkout', 'paypal_checkout_normal']
  return buyScene.includes(from) || buyScene.includes(bi)
}

async function showAttentivePopup({ isShowAttentivePopup = true } = {}) {
  // 非us站点(shein美国站点也不弹出attentive,目前只有romwe弹出)
  if ( SiteUID !== 'rwmus') return false

  if (isFromShopping()) return false

  // abt 没开
  if (!getSmsAbtResult()) return false

  // 所有游戏页去除短信验证弹窗
  if (!isShowAttentivePopup) return false

  const { show } = await checkAttenBindInfo()

  // 展示
  if (show) showAttentiveA()

  return !!show
}

async function showPopQueue(res) {
  // 非登录态
  if (!res?.info?.member) return
  // 登录回调参数需要在异步调用之前，不然登录成功会清除上一次的登陆请求配置
  const { isShowAttentivePopup = true } = window.SHEIN_LOGIN?.instanceActions?.getOpts() || {}
  await registerPopInitData(res)
  showAttentivePopup({ isShowAttentivePopup })
}


export function clearAbtInfo(res) {
  if (res && res.info && res.info.member) {
    window._abt_server_provider &&
      window._abt_server_provider.clear &&
      window._abt_server_provider.clear()
  }
}

/**
 * 登录注册错误码统一处理
 * @param object res 接口返回内容对象
 */
export function formatNormalErrorMessage(res = {}) {
  const langText = window.loginLanguage || {}
  const codeTips = {
    402901: langText.SHEIN_KEY_PWA_16274,
    402902: langText.SHEIN_KEY_PWA_17536,
    402903: langText.SHEIN_KEY_PWA_16275,
    402904: langText.SHEIN_KEY_PWA_17536,
    400529: langText.SHEIN_KEY_PWA_16817,
    400584: langText.SHEIN_KEY_PWA_17354,
    400592: langText.SHEIN_KEY_PWA_17541,
    400503: langText.SHEIN_KEY_PWA_15590
  }

  const defaultValue = langText.SHEIN_KEY_PWA_14899 || ''

  const { code = '', info = {}, tips = '' } = res

  let msg = codeTips[code] || tips || defaultValue || ''

  if (['402902', '402904'].includes(code.toString())) {
    msg = msg.replace('{0}', info.lock_time)
  }
  return msg
}

// 组装新隐私政策协议
export function combineNewPrivacyLanguage(opt = {}) {
  const { from = '', abt = '', type = '', language = {}, location = '' } = opt
  const parent = typeof window != 'undefined' ? window : {}
  const langText = parent.loginLanguage || language || {}
  let str = ''

  const privacyText =
    langText.SHEIN_KEY_PWA_15088 || gbCommonInfo?.language?.SHEIN_KEY_PWA_15088 || ''
  const privacy = `<a fsp-key="privacy-cookies" href="javascript:void(0);" class="j-privacy-and-cookies" da-event-click="2-8-59" da-event-expose="2-8-60" data-location="${location}">${privacyText}</a>`

  str = abt == 'yes' ? langText.SHEIN_KEY_PWA_17944 : langText.SHEIN_KEY_PWA_17946

  if (from === 'third' && abt == 'no') {
    str = type == 'login' ? langText.SHEIN_KEY_PWA_17945 : langText.SHEIN_KEY_PWA_17946
  }

  if (from == 'pop' && abt == 'yes') {
    str = `<h3>${langText.SHEIN_KEY_PWA_18697}</h3><p>${langText.SHEIN_KEY_PWA_18698}</p><br/><p>{0}<br/>{1}</p>`
  }

  if (from == 'newui-continue') {
    str = langText.SHEIN_KEY_PWA_24066
  }

  const termsText =
    langText.SHEIN_KEY_PWA_15608 || gbCommonInfo?.language?.SHEIN_KEY_PWA_15608 || ''
  const terms = `<a fsp-key="terms-conditions" href="javascript:void(0);" class="j-terms-and-conditions" da-event-click="2-8-61" da-event-expose="2-8-62" data-location="${location}">${termsText}</a>`

  return template(privacy, terms, str)
}

export function handleNewPrivacyClick(e) {
  const el = e.target
  if (el.tagName == 'A' && el.className.includes('j-privacy-and-cookies')) {
    window.SHEIN_GLOBAL_PRIVACY.show({ type: 'privacy' })
  }
  if (el.tagName == 'A' && el.className.includes('j-terms-and-conditions')) {
    window.SHEIN_GLOBAL_PRIVACY.show({ type: 'termsAndConditions' })
  }
  if (el.tagName == 'A' && el.className.includes('j-terms-of-use')) {
    window.SHEIN_GLOBAL_PRIVACY.show({ type: 'termsOfUse' })
  }
  if (el.tagName == 'A' && el.className.includes('j-terms-of-sale')) {
    window.SHEIN_GLOBAL_PRIVACY.show({ type: 'termsOfSale' })
  }
}

export const newPrivacyCache = {
  key: 'isAgreenNewPrivacy',
  get(locationId = '') {
    try {
      const key = locationId || 'none'
      const val = JSON.parse(sessionStorage.getItem(`${this.key}`) || null) || {}
      return val?.[key] || ''
    } catch (e) {
      return ''
    }
  },
  set(locationId) {
    try {
      const key = locationId || 'none'
      const val = JSON.parse(sessionStorage.getItem(`${this.key}`) || null) || {}
      val[key] = 1
      sessionStorage.setItem(this.key, JSON.stringify(val))
    } catch (e) {
      return ''
    }
  }
}

export function loadWindowLoginLanguage(cb) {
  if (window.loginLanguage) return
  schttp({ url: '/api/common/language/get', params: { page: 'login' } }).then(res => {
    window.loginLanguage = res.language || {}
    cb && cb(res.language)
  })
}

export function reloadOneTrustBanner(member = {}) {
  const { IS_SUPPORT_ONETRUST } = gbCommonInfo
  if (!IS_SUPPORT_ONETRUST) return

  const { onetrust = {} } = member
  const { onetrustId, onetrustToken } = onetrust
  if (!onetrustId) return

  window.localStorage.setItem('_ocs_info_', JSON.stringify({ id: onetrustId, t: onetrustToken }))

  if (window.OneTrust)
    window.OneTrust.dataSubjectParams = { id: onetrustId, token: onetrustToken, isAnonymous: false }

  const otConsentSdk = document.getElementById('onetrust-consent-sdk')
  if (otConsentSdk) {
    otConsentSdk.remove()
  }
  if (window.OneTrust != null && window.OneTrust.Init) {
    window.OneTrust.Init()
    setTimeout(function () {
      window.OneTrust.LoadBanner()

      const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings')

      for (var i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function (event) {
          event.stopImmediatePropagation()
          window.OneTrust.ToggleInfoDisplay()
        }
      }

      reloadOneTrustEvents()
    }, 1000)
  }
}

// 获取siteuid对应的国家
export function getSiteUidCountry(list) {
  const { SiteUID } = gbCommonInfo
  const siteuidCountry = list.filter(v => {
    if (SiteUID == 'm') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'm'
    }
    if (SiteUID == 'rwm') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'm'
    }
    return v.siteUid == SiteUID
  })
  return siteuidCountry
}

// 获取站点默认对应的国家
export function getSiteToDefaultCountry(supportCountry) {
  const { countryAbbr: ipCountryCode } = UserInfoManager.getSync({ key: 'Country', actionType: 'login/getSiteToDefaultCountry' }) || {}
  const ipCountry = supportCountry.find(v => v.value == ipCountryCode)
  if (ipCountry) return ipCountry

  const fuzzyCode = getFuzzyDefaultLocation(supportCountry)
  if (fuzzyCode) return fuzzyCode

  const siteuidCountry = getSiteUidCountry(supportCountry)
  if (siteuidCountry?.length > 0) return siteuidCountry[0]
  return supportCountry[0] || {}
}

export function getFuzzyDefaultLocation(supportCountry) {
  const { SiteUID } = gbCommonInfo
  //模糊匹配
  const key = SiteUID.match(/^(m|pw|rwm)([a-z]+)$/)?.[2] || ''
  const fuzzyCode = supportCountry.find(v => v.value.includes(key.toUpperCase()))
  if (fuzzyCode) return fuzzyCode
  return ''
}

// 手机号码区号缓存
export const phoneCodeCache = {
  key: 'p_c_s_cache',
  set(v, k) {
    let vl = window.localStorage.getItem(this.key) || null
    try {
      vl = JSON.parse(vl) || {}
    } catch (e) {
      vl = {}
    }
    vl[k] = v
    window.localStorage.setItem(this.key, JSON.stringify(vl))
  },
  get() {
    let vl = window.localStorage.getItem(this.key)
    try {
      vl = JSON.parse(vl) || {}
    } catch (e) {
      vl = {}
    }
    return vl[1] || vl[0] || ''
  },
  getValue(k) {
    let vl = window.localStorage.getItem(this.key)
    try {
      vl = JSON.parse(vl) || {}
    } catch (e) {
      vl = {}
    }
    return vl[k] || ''
  },
  setValue(vl) {
    window.localStorage.setItem(this.key, JSON.stringify(vl))
  },
  getStr() {
    let vl = window.localStorage.getItem(this.key)
    try {
      vl = JSON.parse(vl) || {}
    } catch (e) {
      vl = {}
    }
    let str = ''
    if (vl[1]) str += `1-${vl[1]}_`
    if (vl[0]) str += `0-${vl[0]}`
    return str
  },
  parseStr(str) {
    if (!str) return ''
    let vl = str.split('_')
    let obj = {}
    vl.forEach(v1 => {
      const [k, v] = v1.split('-')
      if (k) obj[k] = v
    })
    return {
      code: obj[1] || obj[0] || '',
      value: obj
    }
  }
}

export const sleep = (timeout = 200) => new Promise(r => setTimeout(r, timeout))

// 是否展示邮件订阅
export function isShowEmailSubscribe() {
  const { SIGNUP_SUBSCRIPTION_SUPPORT, SIGNUP_SUBSCRIPTION_NO_SUPPORT } = gbCommonInfo
  return SIGNUP_SUBSCRIPTION_SUPPORT || SIGNUP_SUBSCRIPTION_NO_SUPPORT || false
}

// 是否展示手机订阅
export function isShowPhoneSubscribe() {
  const { SiteUID, SMS_SUBSCIRIPE_DEFAULT_SELECTED, SMS_SUBSCIRIPE_DEFAULT_NO_SELECTED } =
    gbCommonInfo
  // us站不展示手机短信订阅
  if (['pwus'].includes(SiteUID)) return false

  // 手机号默认订阅勾选
  const default_selected = SMS_SUBSCIRIPE_DEFAULT_SELECTED.split?.(',')
  // 如果阿波罗配置手机号码默认勾选，展示手机号订阅
  if (default_selected.includes(SiteUID)) return true

  // 手机号默认订阅不勾选
  const default_no_selected = SMS_SUBSCIRIPE_DEFAULT_NO_SELECTED.split(',')
  // 如果阿波罗配置手机号码默认勾选，展示手机号订阅
  if (default_no_selected.includes(SiteUID)) return true

  return false
}

export function isWhatsAppDefaultChecked() {
  const { SiteUID, WHATSAPP_SUBSCRIPE_CHECKED } = gbCommonInfo
  // const preData = window.__WHATSAPP_SUBSCRIPE_CHECKED || window.LoginPreloadData || window.SHEIN_LOGIN?.__pre_data || {}
  // const WHATSAPP_SUBSCRIPE_CHECKED = preData.WHATSAPP_SUBSCRIPE_CHECKED || []
  return WHATSAPP_SUBSCRIPE_CHECKED?.includes?.(SiteUID)
}
