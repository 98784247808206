/* global OneTrust,SHEIN_BACKUP_ONETRUST,OptanonActiveGroups */
import { sendLogs, formatLogParams } from './util'
import { getLocalStorage } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager'
let isShowAnimation = true // 如果onetrust前面有弹窗，动画消失，为了兼容展示，特殊处理
let isBackupOnetrustLoaded = false
let isLinkModified = false // 隐私政策链接是否已修改


function setOneTrustDisplay () {
  // 降级模式
  if (isBackupOnetrustLoaded) {
    SHEIN_BACKUP_ONETRUST.show()
    return
  }
  const $el = document.getElementById('onetrust-banner-sdk')
  $el && $el.classList.remove('mshe-none')
  if($el) {
    const darkEl = document.querySelector('#onetrust-consent-sdk .onetrust-pc-dark-filter')
    darkEl && darkEl.classList.add('dark-filter-custom-mask')
  } else { // 通知全局队列next
    console.log('The sdk does not find the dom, execute the global pop-up queue next')
    window._GB_PopUpModalEventCenter_.next('onetrust')
  }
  !isLinkModified && modifyPrivacyLink()
  if (isShowAnimation) {
    addDisplayTransition()
    isShowAnimation = false
  }
}

// 因onetrust配置后台无法根据站点来配置隐私政策链接、需要手动修改链接形式
function modifyPrivacyLink () {
  const { langPath } = gbCommonInfo
  const $el = document.getElementById('onetrust-policy-text')?.getElementsByTagName('a')[0]
  const val = $el?.getAttribute('href')
  if (!val) return
  const avalue = document.createElement('a')
  avalue.href = val
  const link = `${langPath}${avalue.pathname}`
  $el.setAttribute('href', link)
  isLinkModified = true
}

function setOneTrustHidde () {
  // 降级模式
  if (isBackupOnetrustLoaded) {
    SHEIN_BACKUP_ONETRUST.hide()
    return
  }
  const $el = document.getElementById('onetrust-banner-sdk')
  $el && $el.classList.add('mshe-none')
}

function addDisplayTransition () {
  const $el = document.getElementById('onetrust-banner-sdk')
  $el && $el.classList.add('OneTrust-transition', 'OneTrust-animate-hide')
  setTimeout(() => showDisplayTransition(), 200)
}

function showDisplayTransition () {
  const $el = document.getElementById('onetrust-banner-sdk')
  $el && $el.classList.add('OneTrust-animate-show')
}

function addEventInAllowBtn () {
  const el = document.getElementById('onetrust-accept-btn-handler')
  el && el.addEventListener('click', () => {
    window._GB_PopUpModalEventCenter_.next('onetrust')
  })
}
function scrollHandle(e) {
  e.preventDefault()
  e.stopPropagation()
}
function addEventPrevent () {
  const nodeSdk = document.getElementById('onetrust-banner-sdk')
  const nodeDark = document.querySelector('.onetrust-pc-dark-filter')
  const isShow = nodeSdk && window.getComputedStyle(nodeSdk)?.getPropertyValue('display') !== 'none'
  if(nodeSdk && isShow) {
    nodeSdk.addEventListener('touchmove', scrollHandle, { passive: false })
  }
  if(nodeDark && isShow) {
    nodeDark.addEventListener('touchstart', scrollHandle, { passive: false })
    nodeDark.addEventListener('touchmove', scrollHandle, { passive: false })
  }
}

// cookie banner settings右上角关闭按钮
function addEventCookiesSettingClose () {
  const closeIcon = document.querySelector('#onetrust-pc-sdk .ot-pc-header #close-pc-btn-handler')
  closeIcon && closeIcon.addEventListener('click', () => {
    // cookie settings 中关闭close按钮新增遮罩
    const darkEl = document.querySelector('#onetrust-consent-sdk .onetrust-pc-dark-filter')
    const $el = document.getElementById('onetrust-banner-sdk')
    const isShow = $el && window.getComputedStyle($el)?.getPropertyValue('display') !== 'none'
    if(darkEl && isShow) {
      let timerId = setTimeout(() => {
        darkEl.style.visibility = 'visible'
        darkEl.style.opacity = '1'
        clearTimeout(timerId)
      }, 200)
    } else {
      $el && $el?.removeEventListener('touchmove', scrollHandle)
      darkEl && darkEl?.removeEventListener('touchstart', scrollHandle)
      darkEl && darkEl?.removeEventListener('touchmove', scrollHandle)
      // 清除被禁用滚动
      const bodyStyles = document.body?.style
      if (bodyStyles?.overflow === 'hidden' && bodyStyles?.position === 'fixed') {
        let bodyTimeId = setTimeout(() => {
          bodyStyles.overflow = ''
          bodyStyles.position = ''
          clearTimeout(bodyTimeId)
        }, 200)
      }
    }

  })
}

function isMatchSupportPath () {
  const { name } =  window._gb_app_ && window._gb_app_.$route || {}
  const supportPage = ['config_index', 'search', 'page_goods_detail', 'page_select_class', 'page_category', 'shein_picks', 'page_real_class', 'page_daily_new']
 
  return supportPage.includes(name)
}

function watchSpaApplictionRoute () {
  if (isMatchSupportPath()) {
    setOneTrustDisplay()
  }
  window._gb_app_ && window._gb_app_.$watch('$route', () => {
    if (isMatchSupportPath()) {
      setOneTrustDisplay()
    } else {
      setOneTrustHidde()
    }
  })
}

function show () {
  window._GB_PopUpModalEventCenter_.pushQueue('onetrust', () => {
    const lastPopName = window._GB_PopUpModalEventCenter_.lastPopupModalName
    if (lastPopName && lastPopName !== 'onetrust') isShowAnimation = true
    addEventInAllowBtn()
    watchSpaApplictionRoute()
    addEventPrevent()
  })
}

// 修复fastclick 在 cookie 协议按钮里面的点击事件覆盖
function fixIosFastClickEvent () {
  const root = document.getElementById('onetrust-pc-sdk')
  const bts = root.querySelectorAll('.ot-switch-nob')
  if (bts.length > 0) {
    bts.forEach((v) => {
      v.classList.add('needsclick')
    })
  }
}

function init () {
  addEventCookiesSettingClose()
  // 如果是降级模式
  if (isBackupOnetrustLoaded) {
    const isshow = getLocalStorage('isshowbackuponetrust_pop') || '0'
    if (isshow == '0') {
      return show()
    }
    window._GB_PopUpModalEventCenter_.done('onetrust')
    return
  }

  fixIosFastClickEvent()
  const isshow = window.OneTrust && !OneTrust.IsAlertBoxClosed() || false
  window.OneTrust?.OnConsentChanged((v) => { 
    sendLogs(formatLogParams(v.detail || []))
  })
  if (isshow) {
    show()
  } else {
    window._GB_PopUpModalEventCenter_.done('onetrust')
  }
}

function syncLocalLogs () {
  try {
    const memeberId = UserInfoManager.get({ key: 'memberId', actionType: 'handleOneTrust'} ) || ''

    let cache = (localStorage.getItem('SyscLocalOnetrustLogCache') || '').split(',')
    
    if (!memeberId || cache.length == 0 || typeof OptanonActiveGroups === 'undefined') return
    // 已推送
    if (cache.includes(memeberId)) return
    // 未点击过
    if (!window?.OneTrust?.IsAlertBoxClosed()) return
    
    if (cache.length >= 50) cache.shift()
    cache.push(memeberId)
    localStorage.setItem('SyscLocalOnetrustLogCache', cache.join(','))
    sendLogs(formatLogParams(OptanonActiveGroups.split(',')))
  } catch (e) {
    // empty block
  }
}

// 特定路径展示 one trust cookie 弹窗
function initOneTrust () {
  if (typeof window == 'undefined') return
  if (window.isOnetrustBannerLoaded) {
    init()
    return
  }
  window.addEventListener('onetrustBannerLoaded', () => {
    init()
  })

  // backup 降级弹窗展示
  if (window.onetrustBannerLoadedError) InitBackUpOnetrustPop()
  window.addEventListener('onetrustBannerLoadedError', () => InitBackUpOnetrustPop())
}

function InitBackUpOnetrustPop () {
  if (isBackupOnetrustLoaded) return
  isBackupOnetrustLoaded = true
  import(/* webpackChunkName: "backup-onetrust" */'./backup').then(() => { 
    SHEIN_BACKUP_ONETRUST.init(init)
    if (!window.OneTrust) window.OneTrust = {}
    window.OneTrust.ToggleInfoDisplay = SHEIN_BACKUP_ONETRUST.settings
  })
}

initOneTrust()

export function reloadOneTrustEvents () {
  const isshow = window.OneTrust && !OneTrust.IsAlertBoxClosed() || false
  syncLocalLogs()
  if (!isshow) return
  if (isMatchSupportPath()) {
    setOneTrustDisplay()
    return
  }
}

