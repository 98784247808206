// eslint-disable-next-line no-unused-vars, no-redeclare
/*globals __attentive_domain,gbRegisterModule,_abt_server_provider*/
import schttp from 'public/src/services/schttp'
import { asyncLoadFile } from '@shein/common-function'

const { SiteUID } = typeof gbCommonInfo === 'object' ? gbCommonInfo : {}
let abtResult = false
let evtIsListenning = false
export function sendGaShMSms() {
  const saInfo = typeof SaPageInfo !== 'undefined' ? SaPageInfo : { start_time: new Date().getTime() }
  const params = Object.assign({}, saInfo )
  params.activity_name = 'expose_popup_attentivepopup'
  typeof sa !== 'undefined' && sa('send', params)
}
export function getShMSmsAbtData(cb) {
  if (typeof window == 'undefined') return
  if( SiteUID !== 'rwmus') return 
  if (typeof window._abt_server_provider !== 'undefined' && _abt_server_provider.getUserAbtResult) {
    _abt_server_provider.getUserAbtResult({ posKeys: 'SmsN' }).then(function(abtinfo){		
      let data = abtinfo['SmsN']
      const point = data && data.poskey && data.param == 'on'
      abtResult = point
      cb({ isSpecial: false, abt: point })
    }).catch(function(err){
      abtResult = false
      cb({ isSpecial: false, abt: false, err })
    })
  }
}
export function attentiveA(){
  if (window.__attentive_domain) return
  if (SiteUID !== 'rwmus') return 
  const url = 'https://cdn.attn.tv/romwe/dtag.js'
  asyncLoadFile({
    label: 'script',
    attrs: { async: true, defer: true, src: url },
  })
}

export function showAttentiveA() {
  sendGaShMSms()
  if (!evtIsListenning) {
    evtIsListenning = true
    window.addEventListener('attn.close', function () {
      window.sa?.('send', { activity_name: 'click_attentive_unsubscribe' })
    })
    
    window.addEventListener('attn.subscribe', function () {
      console.log('subscribe')
      window.sa?.('send', { activity_name: 'click_attentive_subscribe' })
    })
  }
  const triggerId = 978717
  window.__attentive && window.__attentive.trigger && window.__attentive.trigger(null, null, null, triggerId)
}

export function getSmsAbtResult() {
  return abtResult === true
}

export function checkAttenBindInfo() {
  return new Promise((resolve) => {
    schttp({
      method: 'POST',
      url: `/api/user/attentive/check/cuopon/get`, 
    }).then(data => {
      const show = data?.info?.show || false
      resolve({ show })
    }).catch(() => {
      resolve({ show: false })
    })
  })
}

getShMSmsAbtData(({ abt }) => {
  // 加载attentive资源
  if (abt) attentiveA()
  // attentiveA()
})
