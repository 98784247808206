/* globals _GB_LoginEventCenter_ */
import { template, throttle } from '@shein/common-function'
const { REGISTER_SUCCESS_SWITCH } =
  typeof gbCommonInfo === 'object' ? gbCommonInfo : {}


export const showRegisterSuccessPopFormat = (loginForm) => {
  if(!loginForm || loginForm == '') return false
  const returnValue = REGISTER_SUCCESS_SWITCH.some(item => item.includes(loginForm))
  return returnValue
}

export const registerPopTextFormat = ({ LangText, registerType, point }) => {
  const third = ['Google', 'Facebook', 'Line', 'VK']
  let registerText = ''
  let tipsType = ''
  const pointText = `<span class="point-text">${LangText.SHEIN_KEY_PWA_29147}</span>`
  if(third.includes(registerType)){
    if( point > 0 ){
      registerText =  template(point, pointText, LangText.SHEIN_KEY_PWA_29146)
      tipsType = 'B'
    }
    if(point == 0) {
      registerText = LangText.SHEIN_KEY_PWA_29149
      tipsType = 'C'
    }
  }
  if(registerType == 'email'){
    if( point > 0 ){
      registerText =  template(point, pointText, LangText.SHEIN_KEY_PWA_29146)
      tipsType = 'A'
    }
  }
  if(registerType == 'phone'){
    // TR-36413 强制改密需求单独诉求, 注释手机号注册成功文案
    registerText =  '' // LangText.SHEIN_KEY_PWA_29148
  }
  return { registerText, registerType, tipsType }
}

export const helpPageTipsText = ({ tipsType, registerType, langText, account, point }) => {
  let accountText = ''
  let pointText = ''
  const linkText = langText.SHEIN_KEY_PWA_29157
  if(tipsType == 'A') {
    accountText = template(account, langText.SHEIN_KEY_PWA_29155)
    pointText = template(point, langText.SHEIN_KEY_PWA_29156)
    return { accountText, pointText, linkText }
  }
  if(tipsType == 'B') {
    accountText = template(registerType, langText.SHEIN_KEY_PWA_29154)
    pointText = template(point, langText.SHEIN_KEY_PWA_29156)
    return { accountText, pointText, linkText }
  }
  if(tipsType == 'C') {
    accountText = template(registerType, langText.SHEIN_KEY_PWA_29154)
    return { accountText }
  }
}

export const registerTypeFormat = (res = {}) => {
  const account_type = res?.info?.member?.accountType || res?.info?.member?.account_type
  let registerFrom = ''
  if(account_type == '7'){
    registerFrom = 'email'
  } else if(account_type == '8') {
    registerFrom = 'phone'
  } else if(account_type == '11') {
    registerFrom = 'Facebook'
  } else if(account_type == '12') {
    registerFrom = 'Google'
  } else if(account_type == '13') {
    registerFrom = 'VK'
  } else if(account_type == '15') {
    registerFrom = 'Line'
  }
  return registerFrom
}

export const registerPopInitData = throttle({ func: (res = {}) => {
  return new Promise(resolve => {
    window.requestAnimationFrame(() => {
      _GB_LoginEventCenter_.$emit('show-login-page-reg-suc-modal', { res, cb: resolve })
    })
  })
}, wait: 1000 }) 
